<template>
   <div class="content-wrapper">
      <!-- Content Header (Page header) -->
      <div class="content-header">
         <div class="container-fluid">
            <div class="row mb-2">
               <div class="col-sm-6">
                  <h1 class="m-0">{{ $t("reply_question") }}</h1>
               </div>
               <!-- /.col -->
               <div class="col-sm-6">
                  <ol class="breadcrumb float-sm-right">
                     <li class="breadcrumb-item active">
                        <router-link :to="{ name: 'dashReports' }">{{
                           $t("dashboard")
                        }}</router-link>
                     </li>
                     <li class="breadcrumb-item active">
                        <router-link :to="{ name: 'questions' }">{{
                           $t("questions")
                        }}</router-link>
                     </li>
                     <li class="breadcrumb-item active">{{ $t("reply_question") }}</li>
                  </ol>
               </div>
               <!-- /.col -->
            </div>
            <!-- /.row -->
         </div>
         <!-- /.container-fluid -->
      </div>
      <!-- /.content-header -->

      <!-- Main content -->
      <section class="content">
         <div class="container-fluid">
            <div class="row">
               <div class="col-md-12">
                  <div class="card card-outline card-info">
                     <div v-if="loadingQuestion" class="d-flex justify-content-center">
                        {{ $t("loading") }}
                     </div>

                     <div v-else>
                        <!-- card-header -->
                        <div class="card-header">
                           <h4 class="card-title">
                              <strong>{{ $t("reply_question") }} :</strong> <p> {{ question.question }} </p>
                           </h4>
                        </div>

                        <form @submit.prevent="save">
                           <div class="card-body">
                              <div class="row">
                                 <div class="col-12">
                                    <div class="d-flex flex-column form-group mb-4">
                                       <label>{{ $t("choose_the_type_of_content") }}</label>
                                       <div class="wrapper">
                                          <input v-model="reply.type" type="radio" name="select" id="option-1"
                                             value="text" />
                                          <input v-model="reply.type" type="radio" name="select" id="option-2"
                                             value="video" />
                                          <label for="option-1" class="option option-1 mr-2">
                                             <div class="dot"></div>
                                             <span>{{ $t('text') }}</span>
                                          </label>
                                          <label for="option-2" class="option option-2">
                                             <div class="dot"></div>
                                             <span>{{ $t('link') }}{{ ' ' }}{{ $t('of') }}{{ ' ' }}{{ $t('video') }}</span>
                                          </label>
                                       </div>
                                    </div>
                                 </div>

                                 <div class="col-12">
                                    <label for="content-input">{{ $t('content') }}</label>
                                    <div v-if="reply.type === 'text'" class="form-group mb-4">
                                       <textarea v-model="text" class="form-control"
                                          :placeholder="$t('write_text_response_here')" id="floatingTextarea"></textarea>
                                    </div>
                                    <div v-if="reply.type === 'video'" class="mb-3">
                                       <label for="basic-url" class="form-label">{{ $t('video_url') }}</label>
                                       <div class="input-group">
                                          <span class="input-group-text"
                                             id="basic-addon3">https://www.youtube.com/example-link</span>
                                          <input v-model="video" type="text" class="form-control" id="basic-url"
                                             :placeholder="$t('write_or_paste_video_link_here')" />
                                       </div>
                                       <div class="form-text" id="basic-addon4">
                                          {{ $t('please_provide_valid_link_another_video_service') }}
                                       </div>
                                    </div>
                                 </div>
                              </div>
                              <!-- ./row -->
                           </div>
                           <div class="card-footer">
                              <div class="row justify-content-end">
                                 <SaveButton :buttonName="saveButtonName" :isSaving="isSaving"></SaveButton>
                              </div>
                           </div>
                        </form>
                     </div>
                  </div>
               </div>
               <!-- /.col-->
            </div>
            <!-- ./row -->
         </div>
      </section>
   </div>
</template>

<script>
import { v4 as uuidV4 } from "uuid";
import SaveButton from "../../../components/SaveButton.vue";
import { useToast } from "vue-toastification";
import { mapActions, mapState, mapMutations } from "vuex";
import {getEmbedUrl} from "../../../../../utils/video-embed";

export default {
   name: "ReplyQuestion",
   setup() {
      const toast = useToast();
      return { toast };
   },
   components: {
      SaveButton,
   },
   data() {
      return {
         reply: {
            answer: null,
            type: "text",
            uuid: uuidV4(),
         },
         isAlreadyAnswered: false,
         text: null,
         video: null,
         isLoading: false,
         isEditing: false,
         isSaving: false,
         errors: [],
      };
   },
   computed: {
      ...mapState({
         loadingQuestion: (state) => state.question.currentQuestion.loading,
         question: (state) => state.question.currentQuestion.data,
      }),
      saveButtonName() {
         return this.isEditing ? "edit" : "save";
      },
   },
   async created() {
      const questionUuid = this.$route.params.uuid;
      if (questionUuid) {
         await this.getQuestion(questionUuid);

         if (this.question.answers.length > 0) {
            this.reply.uuid = this.question.answers[0].uuid;
            this.reply.type = this.question.answers[0].type;
            this.reply.answer = this.question.answers[0].answer;

            if (this.reply.type === 'text')
               this.text = this.reply.answer;
            else{
               let srcRegex = /src="([^"]+)"/;
               let match = this.reply.answer.match(srcRegex);
               this.video = match[1];
            }
         }
      }
   },
   methods: {
      ...mapActions(["getQuestion", "editQuestion"]),
      ...mapMutations({
         setCurrentQuestionAnswer: "SET_CURRENT_QUESTION_ANSWER",
         resetCurrentQuestion: "RESET_CURRENT_QUESTION",
      }),
      async save() {
         try {
            this.setAnswerContent();
            if (!this.isAlreadyAnswered){
               this.toast.warning(this.$t("fill_in_the_answer_before_saving"));
               return;
            }
            this.errors = [];
            this.isSaving = true;

            await this.editQuestion(this.question);
            this.resetCurrentQuestion();
            this.$router.push({ name: 'questions' });
            this.toast.success(this.$t("saved"));
         } catch (error) {
            console.error(error);
            this.toast.error(this.$t("save_failure"));
         } finally {
            this.isSaving = false;
         }
      },
      setAnswerContent() {
         if (this.reply.type === "text") {
            if (this.text === null || this.text === "")
               this.isAlreadyAnswered = false;
            else
               this.isAlreadyAnswered = true;


            this.reply.answer = this.text;
         } else if (this.reply.type === "video") {
            if (this.video === null || this.video === "")
               this.isAlreadyAnswered = false;
            else
               this.isAlreadyAnswered = true;

            const url = getEmbedUrl(this.video);
            this.reply.answer = `"<iframe class="ql-video" frameborder="0" allowfullscreen="true" src="${url}" width="100%"></iframe><p style="text-align: center;"><br></p>"`
         }
         this.setCurrentQuestionAnswer(this.reply);
      },
   },
};
</script>


<style scoped>
.wrapper {
   display: inline-flex;
   height: 45px;
   max-width: 350px;
   align-items: center;
   justify-content: space-evenly;
   border-radius: 5px;
}

.wrapper .option {
   background: #fff;
   height: 100%;
   width: 100%;
   display: flex;
   align-items: center;
   justify-content: flex-start;
   border-radius: 5px;
   cursor: pointer;
   padding: 0px;
   border: 2px solid lightgrey;
   transition: all 0.3s ease;
}

.wrapper .option .dot {
   height: 20px;
   width: 20px;
   margin: 0 10px;
   background: #d9d9d9;
   border-radius: 50%;
   position: relative;
}

.wrapper .option .dot::before {
   position: absolute;
   content: "";
   top: 4px;
   left: 4px;
   width: 12px;
   height: 12px;
   background: #0069d9;
   border-radius: 50%;
   opacity: 0;
   transform: scale(1.5);
   transition: all 0.3s ease;
}

input[type="radio"] {
   display: none;
}

#option-1:checked:checked~.option-1,
#option-2:checked:checked~.option-2 {
   border-color: #0069d9;
   background: #0069d9;
}

#option-1:checked:checked~.option-1 .dot,
#option-2:checked:checked~.option-2 .dot {
   background: #fff;
}

#option-1:checked:checked~.option-1 .dot::before,
#option-2:checked:checked~.option-2 .dot::before {
   opacity: 1;
   transform: scale(1);
}

.wrapper .option span {
   font-size: 16px;
   color: #808080;
}

#option-1:checked:checked~.option-1 span,
#option-2:checked:checked~.option-2 span {
   color: #fff;
}
</style>
