const getEmbedUrl = (url) => {
   if (url.includes("youtu")) {
      return youtubeEmbedUrl(url);
   } else if (url.includes("vimeo")) {
      return vimeoEmbedUrl(url);
   } else {
      return "Serviço de vídeo não suportado.";
   }
};

const youtubeEmbedUrl = (url) => {
   const regExp =
      /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
   const match = url.match(regExp);
   const videoId = match && match[7].length === 11 ? match[7] : false;
   return videoId
      ? `https://www.youtube-nocookie.com/embed/${videoId}`
      : "URL inválida.";
};

const vimeoEmbedUrl = (url) => {
   if (url.includes("https://player.vimeo.com/video")){
      return url;
   }
   else {
      const regExp = /vimeo\.com\/(\d+)\/?(\w+)?/;
      const match = url.match(regExp);
      const videoId = match ? match[1] : null;
      const secondId = match && match[2] ? match[2] : null;

      if (videoId) {
         let embedUrl = `https://player.vimeo.com/video/${videoId}`;
         if (secondId) {
            embedUrl += `?h=${secondId}`;
         }
         return embedUrl;
      } else {
         return "URL inválida.";
      }
   }
};

export { getEmbedUrl, youtubeEmbedUrl, vimeoEmbedUrl };
